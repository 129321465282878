<template>
  <div class="bg-black-0 rounded p-3 shadow-sm">
    <h1>Buat Barcode</h1>
    <b-form @submit.prevent="storeAndSaveData">
      <b-row>
        <b-col md="6">
          <b-form-group label="Prefix (Huruf Awal)">
            <b-form-input v-model="barcodeConf.config.prefix" />
          </b-form-group>

          <b-form-group label="Suffix (Huruf Akhir)">
            <b-form-input v-model="barcodeConf.config.suffix" />
          </b-form-group>

          <b-form-group label="Nilai Awal">
            <b-form-input
              v-model="startValue"
              @input="validateNumTypeInput"
            />
          </b-form-group>

          <b-form-group label="Jumlah Barcode">
            <b-form-input
              v-model.number="countDuplicateBarcode"
              type="number"
            />
          </b-form-group>

          <b-form-group label="Jumlah Duplikasi per Barcode">
            <b-form-input
              v-model.number="duplicationCount"
              type="number"
            />
          </b-form-group>

          <!-- <b-form-group label="Kode Barcode">
            <div class="position-relative">
              <b-form-textarea
                v-model="inputCodes"
                readonly
                class="w-100"
                rows="5"
                style="font-size: 16px"
                @input="updateCounter"
              />
              <div
                class="h1 text-danger"
                style="position: absolute; right: 10px; top: 10px"
              >
                {{ counterPlaceholder }}
              </div>
            </div>
          </b-form-group>
          <span>{{ errorMessages }}</span> -->
        </b-col>

        <b-col md="6">
          <b-card class="barcode-card rounded-sm overflow-auto">
            <div class="barcode-container">
              <div v-if="barcodeData.barcodes.length">
                <div
                  v-for="(barcode, index) in barcodeData.barcodes"
                  :key="index"
                  class="barcode-item"
                >
                  <svg :id="'barcode-' + index" />
                </div>
              </div>
              <div v-else>
                <svg id="single-barcode" />
              </div>
            </div>
          </b-card>
          <b-button
            variant="primary"
            class="mt-3 mb-3 float-right"
            @click="generateBarcodesDuplication"
          >
            Buat Duplikasi Barcode
          </b-button>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col class="d-flex justify-content-end">
          <base-button
            text="Kembali"
            variant="danger"
            class="mr-3"
          />

          <b-button
            type="submit"
            variant="primary"
            :disabled="!isFormValid || isLoading"
          >
            {{ isLoading ? "Menyimpan..." : "Download dan Simpan Barcode" }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'

import JsBarcode from 'jsbarcode'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { useVuelidate } from '@vuelidate/core'
import manageBarcodeAPI from '../../../../../api/barcode/manageBarcodeAPI'

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {
    BButton,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
  },

  data() {
    return {
      isLoading: false,
      barcodeData: { barcodes: [] },
      barcodesForBackend: '',
      errorMessages: null,
      inputCodes: '',
      counterPlaceholder: 0,
      countDuplicateBarcode: 0,
      startValue: 0,
      duplicationCount: 0,
      barcodeConf: {
        config: {
          suffix: '',
          prefix: '',
        },
      },
      barcodeRef: {
        size: '',
        print_value: '',
        results: [],
      },
    }
  },

  computed: {
    uniqueBarcodes() {
      return [...new Set(this.barcodeData.barcodes)]
    },
    isFormValid() {
      return this.barcodeData.barcodes.length > 0
    },
  },

  watch: {
    inputCodes: {
      handler: 'generateBarcodes',
      immediate: true,
    },
  },

  methods: {
    updateCounter() {
      this.counterPlaceholder = this.inputCodes
        .split('\n')
        .filter(code => code.trim() !== '').length
    },

    async downloadBarcodesFromInputTextArea() {
      const zip = new JSZip()
      const duplicateCount = this.duplicationCount || 6

      // Adjusted dimensions for the canvas
      const widthMM = 50 // Increase the width to make the barcode larger
      const heightMM = 20 // Increase the height to accommodate larger text

      // Convert mm to pixels
      const dpi = 600
      const widthPx = Math.round((widthMM * dpi) / 25.4)
      const heightPx = Math.round((heightMM * dpi) / 25.4)

      const promises = this.barcodeData.barcodes.map(
        (barcode, index) => new Promise((resolve, reject) => {
          const svgElement = document.getElementById(`barcode-${index}`)
          const svgData = new XMLSerializer().serializeToString(svgElement)

          // Create an Image element for scaling
          const img = new Image()
          img.onload = () => {
            const canvas = document.createElement('canvas')
            canvas.width = widthPx
            canvas.height = heightPx
            const ctx = canvas.getContext('2d')

            // Clear the canvas
            ctx.clearRect(0, 0, widthPx, heightPx)

            // Scale and draw the barcode
            ctx.drawImage(img, 0, 0, widthPx, heightPx)

            // Convert the canvas to a blob and add it to the ZIP file
            canvas.toBlob(
              blob => {
                const adjustIndex = (index % duplicateCount) + 1
                zip.file(`${barcode}-${adjustIndex}.png`, blob)
                resolve()
              },
              'image/png',
            )
          }

          img.onerror = err => {
            reject(err)
          }

          // Create a Blob URL for the SVG data and load it into the Image element
          const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' })
          const url = URL.createObjectURL(svgBlob)
          img.src = url
        }),
      )

      Promise.all(promises)
        .then(() => {
          zip.generateAsync({ type: 'blob' }).then(content => {
            saveAs(content, 'barcodes.zip')
          })
        })
        .catch(error => {
          console.error('Error generating PNGs:', error)
        })
    },
    generateBarcodes() {
      const codes = this.inputCodes
        .split('\n')
        .filter(code => code.trim() !== '')
        .map(code => code.trim().replace(/,+$/, ''))
      this.barcodeData.barcodes = codes

      this.$nextTick(() => {
        codes.forEach((code, index) => {
          const barcodeContent = code.replace(/,/g, '')

          JsBarcode(`#barcode-${index}`, barcodeContent, {
            format: "CODE128",
            width: 3,
            height: 100,
            displayValue: true,
            textMargin: 5,
            fontSize: 24,
            margin: 10,
            font: 'Arial',
            fontOptions: 'bold',
          })
        })
      })
    },

    generateBarcodesDuplication() {
      const barcodesDuplicate = new Set() // Use a Set to ensure uniqueness

      const prefixValue = this.barcodeConf.config.prefix
      const suffixValue = this.barcodeConf.config.suffix
      const duplicateCountPerBarcode = this.duplicationCount || 6 // This will be used only for display

      // Preserve leading zeros in startValue by keeping it as a string
      const startValueStr = this.startValue
      const startValue = Number(startValueStr.replace(/^0+/, '')) // Convert to number for calculations
      const totalBarcodeCount = this.countDuplicateBarcode

      for (let i = startValue - 1; i < startValue + totalBarcodeCount - 1; i++) {
        // Calculate the zero-padded number
        const paddedNumber = `${i + 1}`.padStart(startValueStr.length, '0')

        // Construct the barcode value with prefix, padded number, and suffix
        const barcodeValue = `${prefixValue}${paddedNumber}${suffixValue}`

        // Add the unique barcode to the Set
        barcodesDuplicate.add(barcodeValue)
      }

      // Convert Set to Array for further manipulation
      const uniqueBarcodes = Array.from(barcodesDuplicate)

      // For displaying barcodes, join with newline for input view
      this.inputCodes = uniqueBarcodes.join('\n')

      // Prepare barcodes for backend as a comma-separated string
      this.barcodesForBackend = uniqueBarcodes.join(',')

      // Assign the comma-separated barcodes to barcodeRef for backend submission
      this.barcodeRef.print_value = this.barcodesForBackend

      this.updateCounter()

      this.$nextTick(() => {
        barcodesDuplicate.forEach((code, index) => {
          JsBarcode(`#barcode-${index}`, code, {
            format: "CODE128",
            width: 3,
            displayValue: true,
            textMargin: 5,
            fontSize: 24,
            margin: 20,
            font: 'Arial',
            fontOptions: 'bold',
          })
        })
      })
    },

    validateNumTypeInput(event) {
      this.startValue = event.target.value.replace(/[^0-9]/g, '')
    },

    async storeBarcodesData() {
      try {
        this.isLoading = true

        const { size } = this.barcodeRef
        const duplicates = this.duplicationCount
        const barcodes = this.uniqueBarcodes

        const formData = new FormData()
        formData.append('size', size)
        formData.append('duplicates', duplicates)
        formData.append('print_value', this.barcodesForBackend)

        try {
          // Make the API call and capture the response
          await manageBarcodeAPI.addBarcode(formData).then(response => {
            // Log the full response
            console.log('Full response:', response.data.data)

            // Initialize flag to track if there are any failed barcodes
            let hasFailedBarcodes = false

            // Check the response for failed barcodes
            Object.keys(response.data.data).forEach(key => {
              const barcodeData = response.data.data[key]
              if (barcodeData.status === 'failed') {
                hasFailedBarcodes = true
              }
            })

            // If all barcodes failed, display a message and abort the download
            const insertFailed = response.data.data.insert_failed || 0
            const insertSuccessful = response.data.data.insert_successful || 0

            if (hasFailedBarcodes && insertSuccessful === 0) {
              console.error('All barcodes failed to be inserted:', response.data)
              this.$bvToast.toast('Semua barcode gagal disimpan. Tidak ada barcode yang akan diunduh.', {
                title: 'Error',
                variant: 'danger',
                solid: true,
              })
              return // Abort the download process
            }

            // If there are some successful inserts, but some barcodes failed, show a warning and prevent download
            if (hasFailedBarcodes && insertSuccessful > 0) {
              console.warn('Some barcodes failed to be inserted:', response.data)
              this.$bvToast.toast('Beberapa barcode gagal disimpan. Tidak ada barcode yang akan diunduh.', {
                title: 'Warning',
                variant: 'warning',
                solid: true,
              })
              return // Abort the download process
            }

            // If all inserts were successful, proceed with the download
            if (!hasFailedBarcodes && insertSuccessful > 0) {
              this.downloadBarcodesFromInputTextArea()
              this.$bvToast.toast('Semua barcode berhasil disimpan dan akan diunduh.', {
                title: 'Success',
                variant: 'success',
                solid: true,
              })
            }
          })
        } catch (err) {
          // Log error
          console.error('Error menyimpan barcode:', err)
          this.$bvToast.toast('Gagal menyimpan beberapa barcode.', {
            title: 'Error',
            variant: 'danger',
            solid: true,
          })
        }
      } catch (error) {
        // Catch and handle any unexpected errors
        this.$bvToast.toast('Gagal menyimpan data', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
        this.responseMessage = error
      } finally {
        this.isLoading = false
      }
    },

    async storeAndSaveData() {
      await this.storeBarcodesData()
    },
  },
}
</script>

<style scoped>
.barcode-card {
  height: 400px;
}

.barcode-container {
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
}

.barcode-item {
  margin-bottom: 10px;
}
</style>
